<template>
  <div id="markdown-content" v-html="content"></div>
</template>

<script>
import MarkdownIt from 'markdown-it';
import MarkdownItModifyToken from 'markdown-it-modify-token';
import { computed, defineComponent, ref, onUpdated, onBeforeUnmount } from 'vue';

import { useStore } from 'vuex';

import { TRANSPARENT_PNG } from '@/utils/media';

import _ from 'lodash';

//USE WITH CARE, BECAUSE OF UNFILTERED HTML!
export default defineComponent({
  name: 'MarkdownContent',
  props: {
    source: {
      type: String
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    },
    plugins: { //Allow giving names of plugins to use
      type: Array,
      required: false,
      default: () => []
    },
  },
  setup(props) {
    const store = useStore();

    const objectURLs = ref({});

    const loadedThroughRelay = computed(() => store.getters['auth/isAuthenticationRelayActive']);

    onUpdated(() => {
      let items = document.querySelectorAll('#markdown-content video source, #markdown-content img, #markdown-content audio');
      items.forEach((mediaItem) => {
        if (mediaItem != null) {
          let currentURL = mediaItem.getAttribute('data-src');

          if (currentURL != null) {
            if (loadedThroughRelay.value) {
              mediaItem.setAttribute('src', currentURL);
            } else { //Load it manually - no relay available 
              store.dispatch('fetchMediaWithAuthentication', currentURL).then((mediaBlobOrUrl) => {
                if (mediaBlobOrUrl != null) {
                  if (mediaBlobOrUrl instanceof Blob) {
                    objectURLs.value[currentURL] = URL.createObjectURL(mediaBlobOrUrl);
                    mediaItem.setAttribute('src', objectURLs.value[currentURL]);
                  } else if (_.isString(mediaBlobOrUrl)) { //Already an URL, use it directly and do not set in objectURLs, does not need to be revoked!
                    mediaItem.setAttribute('src', mediaBlobOrUrl);
                  }
                }
              }).catch((error) => {
                console.error('Error loading media with authentication', error)
                mediaItem.setAttribute('src', currentURL);
              });
            }
          }
        }
      });
    });

    onBeforeUnmount(() => {
      _.forEach(objectURLs.value, (objectURL) => {
        URL.revokeObjectURL(objectURL.value);
      });
      objectURLs.value = {};
    });

    const md = ref(new MarkdownIt({...props.options,
      html: true,
      modifyToken: function (token, env) {
        //First apply internal transformations
        //TODO Make video if a value hints to video. Maybe use a get parameter, maybe also for mimeType, then no video separately necessary?
        //If a modify function is included in the options, call it too
        if (props.options != null && props.options.modifyToken != null) {
          props.options.modifyToken(token, env);
        }

        //Set sources as another parameter to be correctly loaded depending on the platform
        if (token.type === 'image' || token.type === 'video') {
          if (token.attrObj.src !== null) {
            token.attrObj['data-src'] = token.attrObj.src;
            token.attrObj.src = TRANSPARENT_PNG; //Set image to initially empty image to avoid network errors
          }
        }
      }
    }));
    md.value.use(MarkdownItModifyToken);

    for (const pluginName of _.uniq(props.plugins)) {
      switch (pluginName) {

        default:
          break;
      }
    }

    const content = computed(() => {
      if (props.source != null) {
        try {
          let htmlSource = md.value.render(props.source);

          return htmlSource;
        } catch {
          console.error('Could not parse markdown source!');
        }
      }
      return null;
    });

    return { content };
  },
});
</script>